import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Tarifa.css'

const Tarifa = (props) => {

    const [tarifa, setTarifa] = useState(0)

    useEffect( () => {
		const apiUrl =  'https://api-tarifa.tabsa.cl/api/tarifario/pantalla-informativa/1'

		const fetchData = async () => {
			const result = await axios(apiUrl)
			const data = await result.data
            
			setTarifa(data)
            //console.log('data: '+data)
		}
		fetchData()
	}, [])
    //console.log("tarifa: "+ tarifa)
    
    return (
        tarifa
        ?    <div class="tarifa">
                <table>
                    <thead>
                        <tr>
                            <th>TARIFA</th>
                            <th>CLP</th>
                            <th>USD</th>
                            {/* <th>EUR</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {tarifa.slice(0, 5).map (({id_tarifa,nombre_tarifa,valor,valor_dolar}) => (
                            ( 
                                <tr key={`tarifa-${id_tarifa}`} style={{ textTransform: 'uppercase'}}>
                                    <td>{nombre_tarifa}</td> 
                                    <td>${valor}</td>
                                    <td>${valor_dolar}</td>
                                    {/* <td className='valor'>${valor.substring(0,valor.length-5)}</td> */}
                                </tr>
                            )
                        ))}
                    </tbody>
                </table>
            </div>    
        : <div></div>
    )             
}
export default Tarifa