import React from 'react'
import Tarifa from './Tarifa'
import Carousel from 're-carousel'
import TarifaUSD from '../tarifaexportacion/TarifaUSD';


const TarifaWidget = () => {	
	return (
        <div className="tarifaContainer">
            <Carousel  axis='x' loop auto duration={1200}><Tarifa/><TarifaUSD/></Carousel>
        </div>
	)
}
export default TarifaWidget;