import React from 'react'
import './Logo.css'
import Logo1 from '../../assets/images/LTABSA.png'

const Logo = () => {
  return (
    <div>
      {/* <img className="logo" alt="TABSA" src= {require('../../assets/images/logo.png')}></img> */}
      <img className="logo" alt="TABSA" src= {Logo1}></img>
    </div>
  )
}

export default Logo;
