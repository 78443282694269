import React from 'react'
import ClimaWidget from '../clima/ClimaWidget'
import Logo from '../logo/Logo'
import Reloj from '../reloj/Reloj'
import './Footer.css'

const Footer = () => {
    return (
        <section className="footer">
            <div id="footer" className="row flex-space-between">
                <div id="masInformacion">
                    {/* <ClimaWidget />
                     */}
                    <Logo/> <p className='prueba'>|</p>
                    <Reloj /> <p className='prueba'>|</p>
                    <ClimaWidget />
                    {/* <Reloj /> */}
                </div>
            </div>
        </section>
    )
}
export default Footer